import { createApp } from "vue";
import { createPinia } from "pinia";
// import * as Sentry from "@sentry/vue";
//@ts-ignore
import VueApexCharts from "vue3-apexcharts";
import TextClamp from "vue3-text-clamp";
//@ts-ignore
import vClickOutside from "click-outside-vue3";
import "@stripe/stripe-js";

import App from "./App.vue";
import router from "./plugins/router";

import "./styles/index.scss";
import "/node_modules/flag-icons/css/flag-icons.min.css";

const VUE_APP = createApp(App);

// Sentry.init({
//     app: VUE_APP,
//     dsn: import.meta.env.VITE_SENTRY_DSN,
//     integrations: [
//         new Sentry.BrowserTracing({
//             // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//             tracePropagationTargets: ["localhost", /^https:\/\/api(-dev)?\.portal\.risify\.pl\/v1/],
//             routingInstrumentation: Sentry.vueRouterInstrumentation(router)
//         }),
//         new Sentry.Replay()
//     ],
//     // Performance Monitoring
//     tracesSampleRate: 1.0, // Capture 100% of the transactions
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

const pinia = createPinia();

VUE_APP.use(router);
VUE_APP.use(pinia);
VUE_APP.use(VueApexCharts);
VUE_APP.use(TextClamp);
VUE_APP.use(vClickOutside);
VUE_APP.mount("#app");
