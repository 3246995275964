import { MenuItem } from "./types/general";
import blikImage from "./assets/imgs/payments/blik.svg";
import transferImage from "./assets/imgs/payments/transfer.svg";
import cardImage from "./assets/imgs/payments/credit-card.svg";

export const colors: Record<string, string> = {
    primary: "#02a86a",
    primary_light: "#d7f1e6",
    primary_dark: "#113A29",
    secondary: "#FFBF52",
    secondary_light: "#FFEADD",
    secondary_dark: "#DB9B60",
    error: "#ec2f23",
    success: "#14B8A6",
    red_accent: "#C2664E",
    gray_accent: "#f5f5f5",
    default: "#000",
    white: "#fff"
};

export const menuItems: MenuItem[] = [
    {
        label: "Baza wiedzy",
        route: "/articles"
    },
    {
        label: "Kalendarz",
        route: "/calendar"
    },
    {
        label: "Czat z terapeutą",
        route: "/chats"
    },
    {
        label: "Ustawienia",
        route: "/settings"
    },
    {
        label: "Regulaminy i polityki",
        route: "/"
    },
    {
        label: "FAQ",
        route: "/faq"
    },
    {
        label: "Wyloguj się",
        route: "/"
    }
];

export const settingsMenuItems: MenuItem[] = [
    {
        label: "Preferencje",
        route: "/"
    },
    {
        label: "Twoje dane",
        route: "/settings/personal-data"
    },
    {
        label: "Dane do logowania",
        route: "/settings/login-data"
    },
    {
        label: "Powiadomienia",
        route: "/settings/notifications"
    },
    {
        label: "Płatności",
        route: "/settings/payments"
    }
];

export const PAYMENTS_METHODS = [
    {
        name: "BLIK",
        value: "blik",
        image: blikImage
    },
    {
        name: "Przelewy24",
        value: "transfer",
        image: transferImage
    },
    {
        name: "Karta kredytowa",
        value: "card",
        image: cardImage
    }
];

export const MANAGEMENT_OPTIONS = [
    {
        id: "reschedule",
        title: "Przełóż wizytę",
        description_active: "Zmiana możliwa do 24H przed sesją",
        description_inactive: "Zmiana była możliwa do 24H przed sesją"
    },
    {
        id: "cancel",
        title: "Anuluj wizytę",
        description_active: "Zwrot pieniędzy jest możliwy do 24H przed sesją",
        description_inactive: "Zwrot pieniędzy był możliwy do 24H przed sesją"
    }
];

export const CANCEL_REASONS = [
    {
        value: "reason1",
        text: "Brak czasu"
    },
    {
        value: "reason2",
        text: "Brak pieniędzy"
    },
    {
        value: "reason3",
        text: "Brak chęci"
    },
    {
        value: "reason4",
        text: "Inny"
    }
];
