import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: RouteRecordRaw[] = [
    {
        path: "/quiz/:url_name",
        component: () => import("../views/Quiz/QuizLayout.vue"),
        children: [
            {
                path: "",
                name: "quiz",
                component: () => import("../views/Quiz/QuizLoader.vue")
            },
            {
                path: ":board_id",
                name: "quiz-board",
                component: () => import("../views/Quiz/QuizBoard.vue")
            }
        ]
    },
    {
        path: "/quiz-tymczasowe-zakonczenie",
        name: "quiz-endpage",
        component: () => import("../views/Quiz/TempEndPage.vue")
    },
    {
        path: "/:pathMatch(.*)*",
        name: "e404",
        component: () => import("../views/E404.vue")
    }
];

const router = createRouter({
    routes,
    history: createWebHistory()
});

router.beforeEach(to => {
    if (
        import.meta.env.PROD &&
        to.name &&
        ["quiz", "quiz-board", "e404"].indexOf(to.name.toString()) === -1
    ) {
        return {
            name: "e404"
        };
    }

    return true;
});

export default router;